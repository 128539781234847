import ApiService from "@/core/services/ApiService";
import {
  CpmiData,
  Disnaker,
  Imigrasi,
  Keberangkatan,
  Transaction,
  Opp,
  Visa,
  TransactionInquiry,
} from "@/core/types/gws-agent/cpmi-detail/CpmiData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MasterCpmiStatus } from "@/core/config/MasterCpmiStatus";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { CPMIStatusEnum } from "@/store/enums/StatusEnums";
import { FormDataPreMedical } from "@/core/types/gws-global-feature/cpmi/FormDataPreMedical";
import {
  BlkServiceApplication,
  ReqFiles,
} from "@/core/types/gws-global-feature/cpmi/BlkServiceApplication";
import {
  ApplicationItem,
  MedicalServiceApplication,
} from "@/core/types/gws-global-feature/cpmi/MedicalServiceApplication";
import { LspServiceApplication } from "@/core/types/gws-global-feature/cpmi/LspServiceApplication";
import {
  formatDateTimeCustom,
  simpleFormatDate,
} from "@/core/helpers/date-format";

export const useCpmiDetailFormStore = defineStore({
  id: "cpmiDetailFormStore",
  state: () => {
    return {
      stateLoading: false,
      stateCpmiData: {} as CpmiData,
      stateCpmiTransactionData: {} as Transaction,
      stateCpmiTransactionInquiryData: {} as TransactionInquiry,
      stateCpmiMedicalData: {} as FormDataPreMedical,
      stateCpmiBlkData: {} as BlkServiceApplication,
      stateCpmiLspData: {} as LspServiceApplication,
      stateCpmiDisnakerData: {} as Disnaker,
      stateCpmiImigrationData: {} as Imigrasi,
      stateCpmiOppData: {} as Opp,
      stateCpmiVisaData: {} as Visa,
      stateCpmiDepartureData: {} as Keberangkatan,
      stateCpmiBlkDocData: {} as ReqFiles,
      stateMasterCpmiStatus: MasterCpmiStatus,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
      statusEnum: CPMIStatusEnum,
      stateSelectedBlkId: "",
      stateSelectedPaymentMethodId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    cpmiData(state) {
      return state.stateCpmiData;
    },
    cpmiTransactionData(state) {
      return state.stateCpmiTransactionData;
    },
    cpmiTransactionInquiryData(state) {
      return state.stateCpmiTransactionInquiryData;
    },
    cpmiBLKData(state) {
      return state.stateCpmiBlkData;
    },
    cpmiLspData(state) {
      return state.stateCpmiLspData;
    },
    cpmiBLKDocData(state) {
      return state.stateCpmiBlkDocData;
    },
    cpmiMedicalData(state) {
      return state.stateCpmiMedicalData;
    },
    errors(state) {
      return state.stateErrors;
    },
    approveStatus(state) {
      let nextStatus = "";
      switch (state.stateCpmiData.status.toString()) {
        case state.statusEnum.PRE_REGISTRATION:
          nextStatus = state.statusEnum.REGISTRATION;
          break;
        case state.statusEnum.REGISTRATION:
          nextStatus = state.statusEnum.PRE_SECURITY;
          break;
        case state.statusEnum.PRE_SECURITY:
          nextStatus = state.statusEnum.PRE_SECURITY_PASSED;
          break;
        case state.statusEnum.MEDICAL_REQUESTED:
          nextStatus = state.statusEnum.MEDICAL_PROCESSED;
          break;
        case state.statusEnum.MEDICAL_PROCESSED:
          nextStatus = state.statusEnum.MEDICAL_RECEIVED;
          break;
        case state.statusEnum.MEDICAL_RECEIVED:
          nextStatus = state.statusEnum.MEDICAL_COMPLETED;
          break;
        default:
          nextStatus = "";
          break;
      }
      return nextStatus;
    },
    rejectedStatus(state) {
      let nextStatus = "";
      switch (state.stateCpmiData.status.toString()) {
        case state.statusEnum.PRE_REGISTRATION:
          nextStatus = state.statusEnum.PRE_REGISTRATION_REJECTED;
          break;
        case state.statusEnum.REGISTRATION:
          nextStatus = state.statusEnum.REGISTRATION_REJECTED;
          break;
        case state.statusEnum.PRE_SECURITY:
          nextStatus = state.statusEnum.PRE_SECURITY_BLACKLIST;
          break;
        default:
          nextStatus = "";
          break;
      }
      return nextStatus;
    },
    error(state) {
      return state.stateError;
    },
  },
  actions: {
    async getCpmiDetail(cpmiId: string) {
      this.stateLoading = true;
      this.stateCpmiData = {} as CpmiData;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<CpmiData> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `cpmi/${cpmiId}`
        );
        const detail = response.data;

        this.stateCpmiData = detail;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },

    async getCpmiTransactionDetail(cpmiId: string, productService: string) {
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<Transaction>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `transaction/${cpmiId}/${productService}`
          );

        const detail = response.data.data;
        this.stateCpmiTransactionData = detail;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async getCpmiTransactionInquiry(
      cpmiId: string,
      paymentMethodId: string,
      productService: string
    ) {
      const params = {
        cpmi_id: cpmiId,
        product_service: productService,
        payment_method_id: paymentMethodId,
      };
      this.stateLoading = true;
      this.stateCpmiTransactionInquiryData = {} as TransactionInquiry;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<TransactionInquiry>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `transaction/inquiry?${new URLSearchParams(params).toString()}`
          );
        const detail = response.data.data;

        this.stateCpmiTransactionInquiryData = detail;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async updateStatus(isApproved: boolean) {
      this.stateLoading = true;
      try {
        const payload = {
          status: isApproved ? this.approveStatus : this.rejectedStatus,
          agent_id: this.stateCpmiData.agent_id,
          company_id: this.stateCpmiData.company_id,
          ref_company_id: this.stateCpmiData.ref_company_id,
          syarikah_id: this.stateCpmiData.syarikah_id,
          first_name: this.stateCpmiData.first_name,
          middle_name: this.stateCpmiData.middle_name,
          last_name: this.stateCpmiData.last_name,
          province_id: this.stateCpmiData.province_id,
          city_id: this.stateCpmiData.city_id,
          nik: this.stateCpmiData.nik,
          birth_place: this.stateCpmiData.birth_place,
          birth_date: this.stateCpmiData.birth_date,
          age: this.stateCpmiData.age?.toString() ?? "",
          gender: this.stateCpmiData.gender,
          phone: this.stateCpmiData.phone,
          email: this.stateCpmiData.email,
          address: this.stateCpmiData.address,
          native_country: this.stateCpmiData.native_country,
          blood_type: this.stateCpmiData.blood_type,
          religion: this.stateCpmiData.religion,
          marital_status: this.stateCpmiData.marital_status,
          passport_number: this.stateCpmiData.passport_number,
          passport_request_date: formatDateTimeCustom(
            this.stateCpmiData.passport_request_date,
            "YYYY-MM-DD"
          ),
          passport_expiry_date: formatDateTimeCustom(
            this.stateCpmiData.passport_expiry_date,
            "YYYY-MM-DD"
          ),
          ktp_file: this.stateCpmiData.ktp_file,
          self_photo_file: this.stateCpmiData.self_photo_file,
          sik_file: this.stateCpmiData.sik_file,
          kk_file: this.stateCpmiData.kk_file,
          passport_file: this.stateCpmiData.passport_file,
          diploma_file: this.stateCpmiData.diploma_file,
          skck_file: this.stateCpmiData.skck_file,
          experience: this.stateCpmiData.experience,
          experience_country: this.stateCpmiData.experience_country,

          country: this.stateCpmiData.country,
          height: this.stateCpmiData.height?.toString() ?? "",
          weight: this.stateCpmiData.weight?.toString() ?? "",
          old_passport_file: this.stateCpmiData.old_passport_file,
          registration_interview_date:
            this.stateCpmiData.registration_interview_date,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateStatusCustom(status: string, id: string) {
      this.stateLoading = true;
      try {
        const payload = {
          status: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateStatusVfs(status: string, id: string) {
      this.stateLoading = true;
      try {
        const payload = {
          status: status,
          criminal_record_status:
            status === "PRE_SECURITY_PASSED" ? "PASS" : "FAIL",
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiMedical(id: string) {
      const payload = this.stateCpmiMedicalData;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}/medical-service-application`,
          payload as AxiosRequestConfig
        );
        console.log(response);
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async commitTransaction(
      cpmiId: string,
      paymentMethodId: string,
      productService: string
    ) {
      const payload = {
        cpmi_id: cpmiId,
        product_service: productService,
        payment_method_id: paymentMethodId,
        product_service_amount:
          this.stateCpmiTransactionInquiryData.product_service_amount,
        payment_fee: this.stateCpmiTransactionInquiryData.payment_fee,
        total_amount: this.stateCpmiTransactionInquiryData.total_amount,
      };

      this.stateLoading = true;
      this.stateCpmiTransactionData = {} as Transaction;
      this.stateLoading = false;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/transaction/commit`,
          payload as AxiosRequestConfig
        );
        const detail = response.data.data;
        this.stateCpmiTransactionData = detail;
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiBlk(id: string) {
      const payload = {
        job_order: this.stateCpmiBlkData.job_order,
        vocational_id: this.stateCpmiBlkData.vocational_id,
        service_date: this.stateCpmiBlkData.service_date,
        requirement_file: this.stateCpmiBlkDocData,
        book_hour: this.stateCpmiBlkData.book_hour,
        blk_id: this.stateSelectedBlkId,
        certificate_package_blk_id:
          this.stateCpmiBlkData.certificate_package_blk_id,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}/services-blk`,
          payload as AxiosRequestConfig
        );
        console.log(response);
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiLsp(id: string) {
      const payload = {
        service_date: this.stateCpmiLspData.service_date,
        services_blk_id: this.stateCpmiLspData.services_blk_id,
        book_hour: this.stateCpmiLspData.book_hour,
        lsp_id: this.stateCpmiLspData.lsp_id,
        certificate_package_blk_id:
          this.stateCpmiLspData.certificate_package_blk_id,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}/services-lsp`,
          payload as AxiosRequestConfig
        );
        console.log(response);
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiDisnaker(id: string) {
      const payload = {
        ktp_file: this.stateCpmiDisnakerData.ktp_file,
        kk_file: this.stateCpmiDisnakerData.kk_file,
        marriage_certificate_file:
          this.stateCpmiDisnakerData.marriage_certificate_file,
        permission_letter_file:
          this.stateCpmiDisnakerData.permission_letter_file,
        bpjs_file: this.stateCpmiDisnakerData.bpjs_file,
        skck_file: this.stateCpmiDisnakerData.skck_file,
        official_statement_letter_file:
          this.stateCpmiDisnakerData.official_statement_letter_file,
        status: "MIGRATION_REQUESTED",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiImigration(id: string) {
      const payload = {
        passport_file: this.stateCpmiImigrationData.passport_file,
        passport_number: this.stateCpmiImigrationData.passport_number,
        passport_request_date:
          this.stateCpmiImigrationData.passport_request_date,
        passport_expiry_date: this.stateCpmiImigrationData.passport_expiry_date,
        job_order_number: this.stateCpmiImigrationData.job_order_number,
        skck_file: this.stateCpmiImigrationData.skck_file,
        bpjs_file: this.stateCpmiImigrationData.bpjs_file,
        blk_certificate_file: this.stateCpmiImigrationData.blk_certificate_file,
        bnsp_certificate_file:
          this.stateCpmiImigrationData.bnsp_certificate_file,
        biometric_certificate_file:
          this.stateCpmiImigrationData.biometric_certificate_file,
        status: "VISA_REQUESTED",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiVisa(id: string) {
      const payload = {
        visa_province_id: this.stateCpmiVisaData.visa_province_id,
        visa_city_id: this.stateCpmiVisaData.visa_city_id,
        visa_center: this.stateCpmiVisaData.visa_center,
        visa_request_date: this.stateCpmiVisaData.visa_request_date,
        passport_file: this.stateCpmiImigrationData.passport_file,
        passport_number: this.stateCpmiImigrationData.passport_number,
        passport_expiry_date: this.stateCpmiImigrationData.passport_expiry_date,
        enjaz_file: this.stateCpmiVisaData.enjaz_file,
        enjaz_number: this.stateCpmiVisaData.enjaz_number,
        status: "VISA_COMPLETED",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiOpp(id: string) {
      const payload = {
        passport_file: this.stateCpmiOppData.passport_file,
        passport_number: this.stateCpmiOppData.passport_number,
        visa_number: this.stateCpmiOppData.visa_number,
        opp_departure_Date: this.stateCpmiOppData.opp_departure_date,
        opp_city_id: this.stateCpmiOppData.opp_city_id,
        opp_date: this.stateCpmiOppData.opp_date,
        flight_country_id: this.stateCpmiOppData.flight_country_id,
        flight_airlines: this.stateCpmiOppData.flight_airlines,
        flight_number: this.stateCpmiOppData.flight_number,
        flight_date: this.stateCpmiOppData.flight_date,
        flight_airport_departure_id:
          this.stateCpmiOppData.flight_airport_departure_id,
        flight_airport_arrival_id:
          this.stateCpmiOppData.flight_airport_arrival_id,
        status: "ORIENTATION",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiDeparture(id: string) {
      const payload = {
        passport_number: this.stateCpmiDepartureData.passport_number,
        visa_number: this.stateCpmiDepartureData.visa_number,
        skck_number: this.stateCpmiDepartureData.skck_number,
        job_order_number: this.stateCpmiDepartureData.job_order_number,
        blk_certificate_number:
          this.stateCpmiDepartureData.blk_certificate_number,
        enjaz_number: this.stateCpmiDepartureData.enjaz_number,
        flight_country_id: this.stateCpmiDepartureData.flight_country_id,
        flight_airlines: this.stateCpmiDepartureData.flight_airlines,
        flight_number: this.stateCpmiDepartureData.flight_number,
        flight_date: this.stateCpmiDepartureData.flight_date,
        flight_airport_departure_id:
          this.stateCpmiDepartureData.flight_airport_departure_id,
        flight_airport_arrival_id:
          this.stateCpmiDepartureData.flight_airport_arrival_id,
        status: "DEPARTURE",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`,
            payload as AxiosRequestConfig
          );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async submitCpmiSyarikah(id: string) {
      const nowDate = new Date();
      const payload = {
        requested_at: formatDateTimeCustom(nowDate.toString(), "YYYY-MM-DD"),
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/certificate-syarikah/${id}`,
          payload as AxiosRequestConfig
        );
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },

    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
