
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "home",
  setup() {
    const currentTheme = ref("light");

    const currentThemeImage = computed(() =>
      require(`@/assets/images/gws-${currentTheme.value}-mode.svg`)
    );

    const gwsHome2 = computed(() =>
      require(`@/assets/images/gws-home-2-${currentTheme.value}.svg`)
    );

    const toggleTheme = () => {
      currentTheme.value = currentTheme.value === "light" ? "dark" : "light";
    };

    return {
      currentTheme,
      currentThemeImage,
      gwsHome2,
      toggleTheme,
    };
  },
});
