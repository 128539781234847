import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { ListItemInquiry } from "@/core/types/gws-master/pengaturan-pembayaran/transaction/ListItemInquiry";

export const useTransactionInquiryListStore = defineStore({
  id: "transactionInquiryListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateDisplayData: {} as ListItemInquiry,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
  },
  actions: {
    async getData(cpmi_id: any, product_service: string, payment_method_id: string) {
      const params = {
        cpmi_id: cpmi_id,
        product_service: product_service,
        payment_method_id: payment_method_id,
      };

      try {
        this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItemInquiry>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `transaction/inquiry?${new URLSearchParams(params).toString()}`
          );
        this.stateDisplayData = response.data.data || {};
      } catch (error) {
        console.log(error);
        this.stateDisplayData = {} as ListItemInquiry;
      } finally {
        this.stateIsLoading = false;
      }
    },
  },
});