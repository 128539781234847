import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d02d1d8a")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href", "id"]
const _hoisted_3 = { class: "d-flex gap-2" }
const _hoisted_4 = ["id", "onClick"]
const _hoisted_5 = ["onClick"]
_popScopeId()


import { useAp2tkiBlkListStore } from "@/store/pinia/gws-ap2tki/master-data/blk/useAp2tkiBlkListStore";
import { ListItem } from "@/core/types/gws-master/master/blk/ListItem";
import { formatDateTime } from "@/core/helpers/date-format";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["delete-data", "edit-data", "toggle-status"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useAp2tkiBlkListStore();

const handleEditData = (item: ListItem) => {
  const editRow = {};
  emits("edit-data", item);
};
const handleToggleStatus = (event) => {
  emits("toggle-status", event);
};
const handleDeleteData = (event) => {
  emits("delete-data", event);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            "min-width": "20",
            type: "index",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID LSP"
          }),
          _createVNode(_component_el_table_column, {
            prop: "pic_name",
            label: "Penanggung Jawab"
          }),
          _createVNode(_component_el_table_column, {
            prop: "user.email",
            label: "Email"
          }),
          _createVNode(_component_el_table_column, { label: "Izin LSP" }, {
            default: _withCtx((scope) => [
              (_unref(checkButtonPermission)('R'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "btn-edit",
                    href: scope.row.license_file,
                    id: scope.id,
                    target: "_blank"
                  }, " Lihat File ", 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { label: "Status" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_switch, {
                modelValue: scope.row.is_active,
                "onUpdate:modelValue": ($event: any) => (scope.row.is_active = $event),
                class: "ml-2",
                style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"},
                onChange: ($event: any) => (handleToggleStatus(scope.row))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: ""
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_3, [
                (_unref(checkButtonPermission)('R'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id,
                      onClick: ($event: any) => (handleEditData(scope.row))
                    }, " Detail ", 8, _hoisted_4))
                  : _createCommentVNode("", true),
                (_unref(checkButtonPermission)('D'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-delete",
                      onClick: ($event: any) => (handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})