import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { ListItemInquiry } from "@/core/types/gws-master/pengaturan-pembayaran/transaction/ListItemInquiry";

export const useTransactionCheckListStore = defineStore({
  id: "transactionCheckListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateDisplayData: {} as any,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
  },
  actions: {
    async getData(cpmi_id: any, product_service: string) {
      try {
        this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `transaction/${cpmi_id}/${product_service}`
        );
        this.stateDisplayData = response.data.data || {};
      } catch (error) {
        console.log(error);
        this.stateDisplayData = {} as any;
      } finally {
        this.stateIsLoading = false;
      }
    },
  },
});
