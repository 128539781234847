
import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { GWS_MASTER_CRUD_URL } from "./urls";
import { FilterData } from "@/core/types/misc/FilterData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { BlkWrapper } from "@/core/types/gws-master/master/blk/BlkWrapper";
import { ListItem } from "@/core/types/gws-master/master/blk/ListItem";
import { buildEntryKey } from "@fullcalendar/core/internal";

export const useAp2tkiBlkListStore = defineStore({
  id: "ap2tkiBlkListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
        search: "",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      stateBlkDetail: {} as ListItem,
      statePaginationLoading: false,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    blkDetail(state) {
      return state.stateBlkDetail;
    }
  },
  actions: {
    async getData(isPageRefresh = true) {
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<BlkWrapper<ListItem[]>>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk?${new URLSearchParams(params).toString()}`
          );
        this.stateDisplayData = response.data?.data.blk;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getBlkDetail(idBlk: string) {
      this.stateBlkDetail = {} as ListItem;
      try {
        const response: AxiosResponse<ApiResponse<ListItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${idBlk}`
          );
        const detail = response.data.data;
        this.stateBlkDetail = detail;
      } catch (error) {
        console.log(error);
      }
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
