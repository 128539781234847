<template>
  <!--begin::Content-->
  <div :class="['row', blackBoxClass]">
    <div :class="[leftColClass, 'p-0 col-12']">
      <div class="card-split-left">
        <img :src="currentImage" alt="" class="stripe-left-img" />
      </div>
    </div>
    <div :class="[rightColClass, 'p-0 col-12']">
      <div class="d-flex align-items-center h-100">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  setup() {
    const store = useStore();
    const route = useRoute();

    // Computed property to determine the current image

    const currentImage = computed(() => {
      return route.path === "/sign-up"
        ? require("@/assets/images/gws-sign-up.png")
        : require("@/assets/images/gws-sign-in.png");
    });

    // Computed property to determine the black-box class
    const blackBoxClass = computed(() =>
      route.path !== "/sign-up" ? "black-box" : ""
    );

    // Computed properties for column classes
    const leftColClass = computed(() =>
      route.path === "/sign-up" ? "col-md-3" : "col-md-7"
    );
    const rightColClass = computed(() =>
      route.path === "/sign-up" ? "col-md-9 grey-container" : "col-md-5"
    );

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      currentImage,
      blackBoxClass,
      leftColClass,
      rightColClass,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-split-left {
  height: 100vh;
  border-radius: 20px;
}

.black-box {
  background-color: black;
}

.grey-container {
  background-color: #f0f2f5;
}

.gws-logo-center {
  width: 277px;
  height: 72px;
}

.stripe-left-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
