import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f1d87128")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
_popScopeId()

import { computed } from "vue";
import { useCpmiListStore } from "@/store/pinia/gws-agent/cpmi/useCpmiListStore";
import { ListItem } from "@/core/types/gws-agent/cpmi/ListItem";
import { useCpmiFormStore } from "@/store/pinia/gws-agent/cpmi/useCpmiFormStore";
import { simpleFormatDateWoHiven } from "@/core/helpers/date-format";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: [
  "delete-data",
  "view-detail",
  "approve",
  "reject",
  "view-detail-modal",
  "view-detail-modal-only",
  "view-interview-modal",
],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useCpmiListStore();
const formStore = useCpmiFormStore();
const authStore = useAuthenticationStore();



const handleShowDetailOnlyModal = (item: ListItem) => {
  formStore.stateUpdateId = item.id;
  emits("view-detail-modal-only", item);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            width: "50",
            type: "index",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "first_name",
            label: "Nama Pencari Kerja",
            sortable: "",
            "min-width": "150",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.first_name) + " " + _toDisplayString(scope.row.middle_name) + " " + _toDisplayString(scope.row.last_name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "employer_name",
            label: "Nama Majikan",
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "employer_phone",
            label: "No. Telp Majikan",
            "min-width": "120"
          }),
          _createVNode(_component_el_table_column, {
            prop: "syarikah",
            label: "Perusahaan Perekrut Kerja",
            "min-width": "100"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.syarikah ? scope.row.syarikah.name : "-"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "contract_start_date",
            label: "Kontrak Berlangsung",
            "min-width": "180"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDateWoHiven)(scope.row.contract_start_date)) + " - " + _toDisplayString(_unref(simpleFormatDateWoHiven)(scope.row.contract_end_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "wages",
            label: "Upah Kerja",
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            label: "Aksi",
            fixed: "right",
            "min-width": "100"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("button", {
                class: "btn-detail",
                onClick: ($event: any) => (handleShowDetailOnlyModal(scope.row))
              }, " Detail ", 8, _hoisted_2)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})