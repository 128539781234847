import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-76a9f6b8")
const _hoisted_1 = ["src"]
_popScopeId()

import { useCpmiDetailFormStore } from "@/store/pinia/gws-global-feature/cpmi/cpmi-detail/useCpmiDetailFormStore";
import { toRef, computed } from "vue";


export default _defineComponent({
  props: {
  visibility: {
    type: Boolean,
    default: false,
  },
},
  emits: ["handle-close"],
  setup(__props, { emit: emits }) {

const props = __props


const isVisible = toRef(props, "visibility");
const cpmiDetailFormStore = useCpmiDetailFormStore();

const transactionData = computed(() => {
  return cpmiDetailFormStore.cpmiTransactionData;
});


const handleCloseModal = () => {
  emits("handle-close");
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(isVisible),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isVisible) ? isVisible.value = $event : null)),
    width: 500,
    "close-on-click-modal": false,
    "show-close": true,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (handleCloseModal()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("iframe", {
        src: _unref(transactionData).action,
        width: "100%",
        height: "600px",
        frameborder: "0"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})