import ApiService from "@/core/services/ApiService";
import { SubmissionData } from "@/core/types/gws-agent/submission/SubmissionData";
import { SiblingsData } from "@/core/types/gws-agent/submission/SiblingsData";
import { CpmiData } from "@/core/types/gws-agent/cpmi-detail/CpmiData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MasterCpmiStatus } from "@/core/config/MasterCpmiStatus";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import {
  SubmissionSkckStatusEnum,
  SubmissionSkckStepEnum,
} from "@/store/enums/StatusEnums";

export const useCpmiSubmissionFormStore = defineStore({
  id: "cpmiSubmissionFormStore",
  state: () => {
    return {
      stateLoading: false,
      stateSubmissionData: {} as SubmissionData,
      stateCpmiData: {} as CpmiData,
      stateMasterCpmiStatus: MasterCpmiStatus,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
      statusEnum: SubmissionSkckStatusEnum,
      stepEnum: SubmissionSkckStepEnum,
      stateSiblingsData: {} as SiblingsData,
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    cpmiData(state) {
      return state.stateCpmiData;
    },
    submissionData(state) {
      return state.stateSubmissionData;
    },
    siblingsData(state) {
      return state.stateSiblingsData;
    },
    approveStatus(state) {
      let nextStatus = "";
      switch (state.stateSubmissionData.status.toString()) {
        case state.statusEnum.APLICATION_INFORMATION:
          nextStatus = state.statusEnum.PERSONAL_DATA;
          break;
        case state.statusEnum.PERSONAL_DATA:
          nextStatus = state.statusEnum.PROCESS_SUBMISSION;
          break;
        case state.statusEnum.PROCESS_SUBMISSION:
          nextStatus = state.statusEnum.PROCESS_SUBMISSION;
          break;
        default:
          nextStatus = "";
          break;
      }
      return nextStatus;
    },
    error(state) {
      return state.stateError;
    },
  },
  actions: {
    async getCpmiDetail(cpmiId: string) {
      this.stateLoading = true;
      this.stateCpmiData = {} as CpmiData;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<any> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `cpmi/${cpmiId}`
        );
        const detailSKCK = response.data.data;
        const detail = response.data.data;
        if (detail.ktp_file) {
          this.stateSubmissionData.ktp_file = detail.ktp_file;
        }
        if (detail.self_photo_file) {
          this.stateSubmissionData.self_photo_file = detail.self_photo_file;
        }
        if (detail.diploma_file) {
          this.stateSubmissionData.diploma_file = detail.diploma_file;
        }
        if (detail.birth_certificate_file) {
          this.stateSubmissionData.birth_certificate_file =
            detail.birth_certificate_file;
        }
        if (detail.kk_file) {
          this.stateSubmissionData.kk_file = detail.kk_file;
        }
        if (detail.sik_file) {
          this.stateSubmissionData.sik_file = detail.sik_file;
        }
        if (detail.blood_type) {
          this.stateSubmissionData.blood_type = detail.blood_type;
        }
        if (detail.religion) {
          this.stateSubmissionData.religion = detail.religion;
        }
        if (detail.marital_status) {
          this.stateSubmissionData.marital_status = detail.marital_status;
        }
        if (detail.address) {
          this.stateSubmissionData.address = detail.address;
        }
        if (detail.email) {
          this.stateSubmissionData.email = detail.email;
        }
        if (detail.phone && !detailSKCK.phone) {
          this.stateSubmissionData.phone = detail.phone;
        }
        if (detail.gender) {
          this.stateSubmissionData.gender = detail.gender;
        }
        if (detail.age) {
          this.stateSubmissionData.age = detail.age;
        }
        if (detail.birth_date) {
          this.stateSubmissionData.birth_date = detail.birth_date;
        }
        if (detail.birth_place) {
          this.stateSubmissionData.birth_place = detail.birth_place;
        }
        if (detail.nik) {
          this.stateSubmissionData.nik = detail.nik;
        }
        if (detail.front_facing_photo_file) {
          this.stateSubmissionData.front_photo_file =
            detail.front_facing_photo_file;
        }
        if (detail.left_facing_photo_file) {
          this.stateSubmissionData.left_photo_file =
            detail.left_facing_photo_file;
        }
        if (detail.right_facing_photo_file) {
          this.stateSubmissionData.right_photo_file =
            detail.right_facing_photo_file;
        }
        if (detail.passport_number) {
          this.stateSubmissionData.passport_number = detail.passport_number;
        }
        if (detail.old_passport_file) {
          this.stateSubmissionData.old_passport_file = detail.old_passport_file;
        }
        if (detail.passport_file) {
          this.stateSubmissionData.passport_file = detail.passport_file;
        }
        if (detail.passport_request_date) {
          this.stateSubmissionData.passport_request_date =
            detail.passport_request_date;
        }
        if (detail.passport_expiry_date) {
          this.stateSubmissionData.passport_expiry_date =
            detail.passport_expiry_date;
        }
        if (detail.signature_file) {
          this.stateSubmissionData.signature_file = detail.signature_file;
        }
        if (detail.height) {
          this.stateSubmissionData.height = detail.height;
        }
        if (detail.weight) {
          this.stateSubmissionData.weight = detail.weight;
        }
        this.stateCpmiData = detail;
        this.stateSubmissionData.first_name = detail.first_name;
        this.stateSubmissionData.middle_name = detail.middle_name;
        this.stateSubmissionData.last_name = detail.last_name;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async getSkckDetail(cpmiId: string) {
      this.stateLoading = true;
      this.stateSubmissionData = {} as SubmissionData;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<SubmissionData>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `skck/cpmi/${cpmiId}`
          );
        const detail = response.data.data;
        this.stateSubmissionData = detail;
      } catch (error) {
        console.log(error);
        this.stateSubmissionData.step = "APPLICATION_INFORMATION";
      }
      this.stateLoading = false;
    },
    async getSkckById(id: string) {
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<SubmissionData>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `skck/form_skck/${id}`
          );
        const detail = response.data.data;
        this.stateSubmissionData.formulir_skck = detail.formulir_skck;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async insertData() {
      this.stateLoading = true;
      try {
        const payload = {
          job_fair_id: this.stateCpmiData.job_fair_id,
          cpmi_id: this.stateCpmiData.id,
          authority_level:
            this.stateSubmissionData.authority_level || "MABES POLRI",
          category: this.stateSubmissionData.category,
          front_photo_file: this.stateSubmissionData.front_photo_file,
          left_photo_file: this.stateSubmissionData.left_photo_file,
          requirement: this.stateSubmissionData.requirement,
          requirement_detail: this.stateSubmissionData.requirement_detail,
          right_photo_file: this.stateSubmissionData.right_photo_file,
          self_photo_file: this.stateSubmissionData.self_photo_file,
          signature_file: this.stateSubmissionData.signature_file,
          destination_country: this.stateSubmissionData.destination_country,
          birth_date: this.stateCpmiData.birth_date ?? "",
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/skck`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
          this.stateLoading = false;
          return response.data.data;
        } else {
          this.stateError = true;
        }
        this.stateLoading = false;
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
    },
    async updateData(skckId: string) {
      this.stateLoading = true;
      const submissionFile = {
        publication_request_letter:
          this.stateSubmissionData.publication_request_letter,
        formulir_skck: this.stateSubmissionData.formulir_skck,
      };
      if (this.stateSubmissionData.formulir_skck) {
        submissionFile.formulir_skck = this.stateSubmissionData.formulir_skck;
      }
      if (this.stateSubmissionData.publication_request_letter) {
        submissionFile.publication_request_letter =
          this.stateSubmissionData.publication_request_letter;
      }
      try {
        const payload = {
          job_fair_id: this.stateCpmiData.job_fair_id,
          cpmi_id: this.stateCpmiData.id,
          authority_level:
            this.stateSubmissionData.authority_level || "MABES POLRI",
          category: this.stateSubmissionData.category,
          front_photo_file: this.stateSubmissionData.front_photo_file,
          left_photo_file: this.stateSubmissionData.left_photo_file,
          requirement: this.stateSubmissionData.requirement,
          requirement_detail: this.stateSubmissionData.requirement_detail,
          right_photo_file: this.stateSubmissionData.right_photo_file,
          self_photo_file: this.stateSubmissionData.self_photo_file,
          signature_file: this.stateSubmissionData.signature_file,
          is_passport: this.stateSubmissionData.is_passport,
          is_kitas: this.stateSubmissionData.is_kitas,
          is_kitap: this.stateSubmissionData.is_kitap,
          is_old_criminal_case:
            this.stateSubmissionData.is_old_criminal_case || false,
          is_lawlessness: this.stateSubmissionData.is_lawlessness || false,
          lawlessness: this.stateSubmissionData.lawlessness || "",
          hobby: this.stateSubmissionData.hobby || "",
          tattoo: this.stateSubmissionData.tattoo || "",
          disability: this.stateSubmissionData.disability || "",
          description: this.stateSubmissionData.description || "",
          first_name: this.stateSubmissionData.first_name,
          middle_name: this.stateSubmissionData.middle_name,
          last_name: this.stateSubmissionData.last_name,
          nik: this.stateSubmissionData.nik,
          birth_place: this.stateSubmissionData.birth_place,
          birth_date: this.stateSubmissionData.birth_date,
          age:
            this.stateSubmissionData.age &&
            String(this.stateSubmissionData.age),
          gender: this.stateSubmissionData.gender,
          phone: this.stateSubmissionData.phone,
          email: this.stateSubmissionData.email,
          job: this.stateSubmissionData.job,
          special_sign: this.stateSubmissionData.special_sign,
          family_position: this.stateSubmissionData.family_position,
          kecamatan: this.stateSubmissionData.kecamatan,
          kelurahan: this.stateSubmissionData.kelurahan,
          address: this.stateSubmissionData.address,
          native_country: this.stateSubmissionData.native_country,
          blood_type: this.stateSubmissionData.blood_type,
          religion: this.stateSubmissionData.religion,
          marital_status: this.stateSubmissionData.marital_status,
          passport_number: this.stateSubmissionData.passport_number,
          passport_request_date: this.stateSubmissionData.passport_request_date,
          passport_expiry_date: this.stateSubmissionData.passport_expiry_date,
          formulir_skck: this.stateSubmissionData.formulir_skck,
          publication_request_letter:
            this.stateSubmissionData.publication_request_letter,
          fingerprint_card: this.stateSubmissionData.fingerprint_card,
          spouse_name: this.stateSubmissionData.spouse_name,
          spouse_age: this.stateSubmissionData.spouse_age,
          spouse_religion: this.stateSubmissionData.spouse_religion,
          spouse_native_country: this.stateSubmissionData.spouse_native_country,
          spouse_job: this.stateSubmissionData.spouse_job,
          spouse_address: this.stateSubmissionData.spouse_address,
          father_name: this.stateSubmissionData.father_name,
          father_age: this.stateSubmissionData.father_age,
          father_religion: this.stateSubmissionData.father_religion,
          father_native_country: this.stateSubmissionData.father_native_country,
          father_job: this.stateSubmissionData.father_job,
          father_address: this.stateSubmissionData.father_address,
          mother_name: this.stateSubmissionData.mother_name,
          mother_age: this.stateSubmissionData.mother_age,
          mother_religion: this.stateSubmissionData.mother_religion,
          mother_native_country: this.stateSubmissionData.mother_native_country,
          mother_job: this.stateSubmissionData.mother_job,
          mother_address: this.stateSubmissionData.mother_address,
          spouse_father_name: this.stateSubmissionData.spouse_father_name,
          spouse_mother_name: this.stateSubmissionData.spouse_mother_name,
          dependent_sibling_name:
            this.stateSubmissionData.dependent_sibling_name,
          dependent_sibling_address:
            this.stateSubmissionData.dependent_sibling_address,
          old_criminal_case: this.stateSubmissionData.old_criminal_case,
          old_verdict: this.stateSubmissionData.old_verdict,
          legal_process_criminal_case:
            this.stateSubmissionData.legal_process_criminal_case,
          current_criminal_case: this.stateSubmissionData.current_criminal_case,
          legal_process_lawlessness:
            this.stateSubmissionData.legal_process_lawlessness,
          height:
            this.stateSubmissionData.height == null
              ? null
              : String(this.stateSubmissionData.height),
          weight:
            this.stateSubmissionData.weight == null
              ? null
              : String(this.stateSubmissionData.weight),
          skin_color: this.stateSubmissionData.skin_color,
          body: this.stateSubmissionData.body,
          head: this.stateSubmissionData.head,
          hair_color: this.stateSubmissionData.hair_color,
          hair_type: this.stateSubmissionData.hair_type,
          face: this.stateSubmissionData.face,
          forehead: this.stateSubmissionData.forehead,
          eye_color: this.stateSubmissionData.eye_color,
          eye_abnormalitiy:
            this.stateSubmissionData.eye_abnormalitiy === ""
              ? null
              : this.stateSubmissionData.eye_abnormalitiy,
          nose: this.stateSubmissionData.nose,
          lips: this.stateSubmissionData.lips,
          teeth: this.stateSubmissionData.teeth,
          chin: this.stateSubmissionData.chin,
          ear: this.stateSubmissionData.ear,
          ktp_file: this.stateSubmissionData.ktp_file,
          sik_file: this.stateSubmissionData.sik_file,
          kk_file: this.stateSubmissionData.kk_file,
          old_passport_file: this.stateSubmissionData.old_passport_file,
          passport_file: this.stateSubmissionData.passport_file,
          birth_certificate_file:
            this.stateSubmissionData.birth_certificate_file,
          diploma_file: this.stateSubmissionData.diploma_file,
          old_skck_file: this.stateSubmissionData.old_skck_file,
          other_phone: this.stateSubmissionData.other_phone,
          bpjs_file: this.stateSubmissionData.bpjs_file,
          destination_country: this.stateSubmissionData.destination_country,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${skckId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
          this.stateLoading = false;
          return response.data.data;
        } else {
          this.stateError = true;
        }
        this.stateLoading = false;
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateEducation(skckId: string, educationData: any) {
      this.stateLoading = true;
      try {
        const payload = {
          educations: educationData,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${skckId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateHistoryCountries(skckId: string, historyCountries: any) {
      this.stateLoading = true;
      try {
        const payload = {
          history_countries: historyCountries,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${skckId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateSiblings(skckId: string, siblingsData: any) {
      this.stateLoading = true;
      try {
        const payload = {
          siblings: siblingsData,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${skckId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateChilds(skckId: string, childsData: any) {
      this.stateLoading = true;
      try {
        const payload = {
          childs: childsData,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${skckId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateStatus(status: string, id: string) {
      this.stateLoading = true;
      try {
        const payload = {
          status: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async updateStep(step: string, id: string) {
      this.stateLoading = true;
      try {
        const payload = {
          step: step,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/skck/${id}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
