<template>
  <div class="card rounded-xl shadow-sm mb-4">
    <div class="card-body p-0">
      <div class="card-px my-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align">
            <h2 class="fw-bolder">{{ title }}</h2>
          </div>
          <div
            class="d-flex gap-2 btn-next align-items-center"
            @click="handleViewDetail"
          >
            <span class="fw-bolder">Lihat Detail</span>
          </div>
        </div>
        <div>
          <button class="btn btn-primary">Daftar Perusahaan</button>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column
            prop="no"
            label=""
            min-width="20"
            type="index"
            align="center"
            fixed
          />
          <el-table-column prop="company_name" label="" min-width="200" fixed />
          <el-table-column
            prop="status.PRE_REGISTRATION"
            label="Pra Pendaftaran"
            min-width="150"
          />
          <el-table-column
            prop="status.REGISTRATION"
            label="Pendaftaran"
            min-width="110"
          />
          <el-table-column
            prop="status.PRE_SECURITY_PASSED"
            label="Pre Security Clearance"
            min-width="200"
          />
          <el-table-column
            prop="status.MEDICAL_COMPLETED"
            label="Pra-Medical"
            min-width="110"
          />
          <el-table-column
            prop="status.BLK_COMPLETED"
            label="Balai Latihan Kerja"
            min-width="150"
          />
          <el-table-column
            prop="status.LSP_COMPLETED"
            label="Lembaga Sertifikasi Profesi"
            min-width="210"
          />
          <el-table-column
            prop="status.DISNAKER"
            label="DISNAKER"
            min-width="100"
          />
          <el-table-column
            prop="status.MIGRATION_COMPLETED"
            label="Imigrasi"
            min-width="100"
          />
          <el-table-column
            prop="status.VISA_COMPLETED"
            label="Pengajuan Visa"
            min-width="150"
          />
          <el-table-column
            prop="status.ORIENTATION"
            label="Orientasi Pra Pemberangkatan"
            min-width="250"
          />
          <el-table-column
            prop="status.DEPARTURE"
            label="Keberangkatan"
            min-width="150"
          />
          <el-table-column
            prop="status.SYARIKAH_VERIFICATION"
            label="Verifikasi Perusahaan Perekrut Kerja"
            min-width="150"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from "@/router";
import { MonitoringKuotaItem } from "@/core/types/gws-master/dashboard/MonitoringKuotaItem";

export default defineComponent({
  name: "CardStatusCPMI",
  props: {
    title: String,
    tableData: {
      type: Array,
      default: [] as MonitoringKuotaItem[],
    },
    detailLink: String,
    tableHeader: {
      type: Array,
      default: null,
    },
  },
  components: {},
  methods: {
    handleViewDetail() {
      if (this.detailLink) {
        router.push(this.detailLink);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.btn-next {
  border: 2px solid #d1d1d1;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
}
</style>
