import { RouterView } from "vue-router";

export default function MasterDataManagementRoutes(staticTitle: string) {
  const verifyPermission = (to, from, next) => {
    const { path } = to;
    const permissions = JSON.parse(
      localStorage.getItem("acc-permission") as string
    );
    const foundRoute = permissions.find((route) => route.route === path);
    if (foundRoute && foundRoute.permissions.includes("R")) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  };
  return [
    {
      path: "/super-admin/master-data/syarikah",
      name: "Perusahaan Perekrut Kerja",
      meta: {
        title: `${staticTitle} - Master - Master Data - Perusahaan Perekrut Kerja`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import(
          "@/views/webtool-superadmin/master-data/syarikah/Index.vue"
        );
      },
    },
    {
      path: "/super-admin/master-data/company",
      name: "Company",
      meta: {
        title: `${staticTitle} - Master - Master Data - Perusahaan (P3MI)`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import("@/views/webtool-superadmin/master-data/agent/Index.vue");
      },
    },
    {
      path: "/super-admin/master-data/p3mi",
      name: "P3MI",
      meta: {
        title: `${staticTitle} - Master - Master Data - P3MI`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import("@/views/webtool-superadmin/master-data/p3mi/Index.vue");
      },
    },
    {
      path: "/super-admin/master-data/medical-facility",
      name: "MedicalFacility",
      meta: {
        title: `${staticTitle} - Master - Master Data - Fasilitas Kesehatan`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import(
          "@/views/webtool-superadmin/master-data/medical-facility/Index.vue"
        );
      },
    },
    {
      path: "/super-admin/master-data/medical-service/",
      component: RouterView,
      children: [
        {
          path: "/super-admin/master-data/medical-service/product",
          name: "Medical Service Products",
          meta: {
            title: `${staticTitle} - Master - Layanan Kesehatan - Produk`,
          },
          beforeEnter: verifyPermission,
          component: () => {
            return import(
              "@/views/webtool-superadmin/master-data/medical-services/product/Index.vue"
            );
          },
        },
        {
          path: "/super-admin/master-data/medical-service/category",
          name: "Medical Service Category",
          meta: {
            title: `${staticTitle} - Master - Layanan Kesehatan - Kategori`,
          },
          beforeEnter: verifyPermission,
          component: () => {
            return import(
              "@/views/webtool-superadmin/master-data/medical-services/category/Index.vue"
            );
          },
        },
        {
          path: "/super-admin/master-data/medical-service/informed-consent",
          name: "Informed Consent",
          meta: {
            title: `${staticTitle} - Master - Layanan Kesehatan - Informed Consent`,
          },
          beforeEnter: verifyPermission,
          component: () => {
            return import(
              "@/views/webtool-superadmin/master-data/medical-services/informed-consent/Index.vue"
            );
          },
        },
      ],
    },
    {
      path: "/super-admin/master-data/blk",
      name: "BLK",
      meta: {
        title: `${staticTitle} - Master - Master Data - BLK`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import("@/views/webtool-superadmin/master-data/blk/Index.vue");
      },
    },
    {
      path: "/super-admin/master-data/lsp",
      name: "LSP",
      meta: {
        title: `${staticTitle} - Master - Master Data - LSP`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import("@/views/webtool-superadmin/master-data/lsp/Index.vue");
      },
    },
    {
      path: "/super-admin/master-data/cpmi",
      name: "Superadmin CPMI",
      meta: {
        title: `${staticTitle} - Master - Master Data - CPMI`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import(
          "@/views/gws-global-feature/cpmi/cpmi-mainpage/Index.vue"
        );
      },
    },
    {
      path: "/super-admin/cpmi-detail/:id",
      name: "Superadmin CPMI Detail",
      component: () =>
        import("@/views/gws-global-feature/cpmi/cpmi-detail/Index.vue"),
    },
    {
      path: "/super-admin/master-data/job-fair",
      name: "JobFair",
      meta: {
        title: `${staticTitle} - Master - Master Data - Bursa Kerja`,
      },
      beforeEnter: verifyPermission,
      component: () => {
        return import(
          "@/views/webtool-superadmin/master-data/jobfair/Index.vue"
        );
      },
    },
  ];
}
