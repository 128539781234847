enum DashboardAgentEnum {
  PRE_REGISTRATION = "Pra Pendaftaran",
  REGISTRATION = "Pendaftaran",
  PRE_SECURITY = "Pre Security Clearance ",
  PRE_MEDICAL = "Pra-Medical",
  BLK = "Balai Latihan Kerja",
  LSP = "Lembaga Sertifikasi Profesi",
  DISNAKER = "DISNAKER",
  MIGRATION = "Imigrasi",
  VISA = "Pengajuan Visa",
  ORIENTATION = "Orientasi Pra Pemberangkatan",
  DEPARTURE = "Keberangkatan",
  SYARIKAH_VERIFICATION = "Verifikasi Perusahaan Perekrut Kerja",
  UPDATE_BP2MI = "Status Update BP2MI",
  UPDATE_SVTS_PASSPORT = "Status Update SVTS + PASSPORT",
  PRE_CLEARENCE_VFS = "Monitoring Status Pre Clearance",
  PASSED = "PASSED",
  BLACKLIST = "BLACKLIST",
  MEDICAL = "Monitoring Status Medical",
  FIT = "FIT",
  UNFIT = "UNFIT",
}

export { DashboardAgentEnum };
