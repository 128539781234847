
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  setup() {
    const store = useStore();
    const route = useRoute();

    // Computed property to determine the current image

    const currentImage = computed(() => {
      return route.path === "/sign-up"
        ? require("@/assets/images/gws-sign-up.png")
        : require("@/assets/images/gws-sign-in.png");
    });

    // Computed property to determine the black-box class
    const blackBoxClass = computed(() =>
      route.path !== "/sign-up" ? "black-box" : ""
    );

    // Computed properties for column classes
    const leftColClass = computed(() =>
      route.path === "/sign-up" ? "col-md-3" : "col-md-7"
    );
    const rightColClass = computed(() =>
      route.path === "/sign-up" ? "col-md-9 grey-container" : "col-md-5"
    );

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      currentImage,
      blackBoxClass,
      leftColClass,
      rightColClass,
    };
  },
});
