<template>
  <div :class="['gws-home', currentTheme]">
    <div class="gws-home-1">
      <div class="d-flex align-items-center justify-content-between mb-10">
        <img
          class="gws-logo-small"
          :src="require('@/assets/images/gws-logo-white-sm.svg')"
        />
        <img :src="currentThemeImage" @click="toggleTheme" />
      </div>

      <div class="mx-auto text-center col-md-8 col-11">
        <h1 class="text-white display-4">
          Revolutionizing Recruitment <br />for a Better Future
        </h1>
        <p class="text-white lead">
          Designed in collaboration with the manpower industry's key
          stakeholders, it creates a safe, government-compliant pathway for
          Indonesian workers to access global opportunities, while meeting
          international standards.
        </p>

        <div
          class="d-flex align-items-center justify-content-center gap-2 mb-10"
        >
          <router-link
            to="/sign-in"
            class="btn btn-custom-primary-purple rounded-pill fw-bolder"
            >Login</router-link
          >
          <router-link
            to="/sign-up"
            class="btn btn-custom-white-purple rounded-pill fw-bolder btn-light"
            >Sign Up</router-link
          >
        </div>
        <p class="text-white">Our Clients</p>
        <div class="d-flex align-items-center justify-content-center">
          <img :src="require('@/assets/images/gws-client.svg')" class="w-100" />
        </div>
      </div>
    </div>

    <div class="themed-section">
      <div class="gws-home-2">
        <div class="d-flex align-items-center p-1 p-md-4 gap-4">
          <div class="display-1">42</div>
          <div class="lead">Agency companies on board our system</div>
        </div>
        <div class="d-flex align-items-center p-1 p-md-4 gap-4">
          <div class="display-1">13</div>
          <div class="lead">Healthcare Fascilities</div>
        </div>
        <div class="d-flex align-items-center p-1 p-md-4 gap-4">
          <div class="display-1">10</div>
          <div class="lead">
            Employment agencies offices across Indonesia with our biometric
            equipment
          </div>
        </div>
        <div class="d-flex align-items-center p-1 p-md-4 gap-4">
          <div class="display-1">25</div>
          <div class="lead">Training Centres</div>
        </div>
      </div>

      <div
        class="gws-home-3 w-100 row align-items-center justify-content-center"
      >
        <div class="col-md-6">
          <img :src="require('@/assets/images/gws-home-3.svg')" class="w-100" />
        </div>

        <div class="col-md-4">
          <h2 class="display-5 mb-4">
            Revolutionizing Recruitment for a Better Future
          </h2>
          <p class="lead lh-lg">
            Technology has transformed every industry and recruitment is no
            different. But this isn’t just about keeping up—it’s about leading.
            The Global Worker System is built for those with vision, endurance,
            and a commitment to putting people first.
          </p>
        </div>
      </div>

      <div class="gws-home-4">
        <span class="gws-pill">Global Worker System</span>
        <div class="row w-100 align-items-center my-10">
          <h2 class="display-5 col-md-4">
            A New Standard for Recruitment Companies.
          </h2>
          <p class="lead lh-lg col-md-6">
            The Global Worker System is an all-in-one, end-to-end platform that
            simplifies recruitment while ensuring compliance with ever-changing
            regulations.
          </p>
        </div>

        <div class="row w-100 align-items-center justify-content-center">
          <div class="col-md-4">
            <div class="p-0 text-center">
              <img :src="require('@/assets/images/gws-home-icon-1.svg')" />
              <h5 class="card-title">Effortless Pre-Registration</h5>
              <p class="lead lh-lg">
                Workers can quickly sign up at job fairs, securely starting
                their journey.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-0 text-center">
              <img :src="require('@/assets/images/gws-home-icon-2.svg')" />
              <h5 class="card-title">Real-Time Trackingl</h5>
              <p class="lead lh-lg">
                Workers receive updates throughout their journey, ensuring
                they’re always informed and connected.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-0 text-center">
              <img :src="require('@/assets/images/gws-home-icon-3.svg')" />
              <h5 class="card-title">End-to-End Support</h5>
              <p class="lead lh-lg">
                Every step is covered—no matter where the worker is in the
                process, support is just a tap away.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="gws-home-5 row w-100 align-items-center">
        <div class="col-md-6">
          <img :src="require('@/assets/images/gws-home-5.svg')" class="w-100" />
        </div>
        <div class="col-md-5 p-10">
          <span class="gws-pill">Beyond Employment</span>
          <h2 class="display-4 mt-5">Beyond Employment:</h2>
          <h2 class="display-5 mb-10">Empowering Workers for the Future</h2>
          <p class="lead lh-lg">
            The Global Worker System doesn’t just secure a job abroad—it builds
            a better future for Indonesian migrant workers.
          </p>
        </div>
      </div>
      <div class="gws-home-6">
        <div class="carousel-wrapper">
          <div id="cardCarousel" class="carousel slide" data-bs-ride="carousel">
            <!-- Indicators -->
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#cardCarousel"
                data-bs-slide-to="0"
                class="active"
              ></button>
              <button
                type="button"
                data-bs-target="#cardCarousel"
                data-bs-slide-to="1"
              ></button>
            </div>

            <!-- Carousel Items -->
            <div class="carousel-inner">
              <!-- First Slide -->
              <div class="carousel-item active">
                <div class="row align-items-stretch justify-content-center">
                  <div class="col-md-3 custom-carousel-wrapper">
                    <div class="card custom-first-card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Verified Work History</h5>
                        <p class="card-text">
                          Workers gain an official, verified record of their
                          overseas experience, boosting their future job
                          prospects.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 custom-carousel-wrapper">
                    <div class="card custom-card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Increased Earning Potential</h5>
                        <p class="card-text">
                          With proven experience, workers can negotiate higher
                          salaries in future roles.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 custom-carousel-wrapper">
                    <div class="card custom-card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Skill Recognition</h5>
                        <p class="card-text">
                          The system showcases skills,certifications, and
                          achievements, making workers more competitive
                          globally.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Second Slide -->
              <div class="carousel-item">
                <div class="row align-items-stretch justify-content-center">
                  <div class="col-md-3 custom-carousel-wrapper">
                    <div class="card custom-first-card h-100">
                      <div class="card-body">
                        <h5 class="card-title">Seamless Re-Employment</h5>
                        <p class="card-text">
                          Workers can smoothly re-enter the job market with
                          their credentials stored in the system.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 custom-carousel-wrapper">
                    <div class="card custom-card h-100">
                      <div class="card-body">
                        <h5 class="card-title">
                          Better Financial Opportunities
                        </h5>
                        <p class="card-text">
                          A verified work history can open doors to financial
                          services like loans or investments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 custom-carousel-wrapper"></div>
                </div>
              </div>
            </div>

            <!-- Controls -->
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#cardCarousel"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#cardCarousel"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-section p-10">
      <div
        class="gws-home-7 row w-100 align-items-center justify-content-center"
      >
        <div class="col-md-4 me-10">
          <h2 class="display-5 mb-10 custom-quote-wrapper">
            The Future of Recruitment
            <img
              :src="require('@/assets/images/gws-quote.svg')"
              class="custom-quote"
            />
          </h2>
          <p class="lead lh-lg">
            The Global Worker System isn’t just a platform; it’s a promise. A
            promise to enhance recruitment for companies and provide long-term
            success for Indonesian migrant workers.
          </p>
        </div>
        <div class="col-md-5">
          <img :src="require('@/assets/images/gws-home-7.svg')" class="w-100" />
        </div>
      </div>
      <div
        class="gws-home-8 row w-100 align-items-center justify-content-center"
      >
        <div class="col-md-5">
          <img :src="require('@/assets/images/gws-home-8.svg')" class="w-100" />
        </div>
        <div class="col-md-4 h-100">
          <div class="custom-bullet-wrapper px-10">
            <img
              :src="require('@/assets/images/custom-bullet.svg')"
              class="custom-bullet"
            />

            <div>
              <h2>Optimized Processes</h2>
              <p>
                By automating operational tasks, recruitment companies can focus
                on what really matters: attracting top talent and building
                relationships.
              </p>
            </div>
          </div>
          <div class="custom-bullet-wrapper px-10">
            <img
              :src="require('@/assets/images/custom-bullet.svg')"
              class="custom-bullet"
            />

            <div>
              <h2>Seamless Communication</h2>
              <p>
                Streamlined communication keeps recruitment companies, overseas
                employers, and workers connected—ensuring everyone stays
                informed.
              </p>
            </div>
          </div>
          <div class="custom-bullet-wrapper px-10">
            <img
              :src="require('@/assets/images/custom-bullet.svg')"
              class="custom-bullet"
            />

            <div>
              <h2>Stronger Worker Protection</h2>
              <p>
                Real-time updates, verified processes, and secure pathways
                safeguard workers while maintaining efficiency.
              </p>
            </div>
          </div>
          <div class="custom-bullet-wrapper px-10">
            <img
              :src="require('@/assets/images/custom-bullet.svg')"
              class="custom-bullet"
            />

            <div>
              <h2>Empowered Companies</h2>
              <p>
                Recruitment companies can efficiently manage placements, monitor
                employment status, and adapt to changing regulations with ease.
              </p>
            </div>
          </div>
          <div class="custom-bullet-wrapper no-border px-10">
            <img
              :src="require('@/assets/images/custom-bullet.svg')"
              class="custom-bullet"
            />

            <div>
              <h2>Building Better Futures</h2>
              <p>
                With tools to document and validate their experiences, workers
                can unlock new opportunities while companies build trust and
                reputation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section">
      <div
        class="gws-home-9 row w-100 align-items-center justify-content-center"
      >
        <div class="col-md-8 text-center">
          <h1 class="display-1 text-white mb-10">A Unified, Global Vision</h1>
          <p class="fs-1 text-white lh-lg">
            With the Global Worker System, recruitment is more than just a
            process—it’s a partnership, a pathway, and a promise for a better
            future.
          </p>
        </div>
      </div>
      <div class="gws-home-10">
        <div
          class="
            row
            w-100
            justify-content-between
            align-items-center
            flex-row-reverse
          "
        >
          <div class="col-md-4">
            <h2 class="text-white lh-lg">
              We grew up with technology in our hands and now we are here to
              solve the most complex challenges with it.
            </h2>
          </div>
          <div class="col-md-4 d-flex flex-column gap-10">
            <img
              :src="require('@/assets/images/gws-logo-footer.png')"
              class="w-75"
            />
            <p>
              A leading data centralization system for the recruitment and
              placement of foreign worker.
            </p>

            <p>©2024 GLOBAL WORKER SYSTEM | SOLUSI ANAK MILENIAL</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "home",
  setup() {
    const currentTheme = ref("light");

    const currentThemeImage = computed(() =>
      require(`@/assets/images/gws-${currentTheme.value}-mode.svg`)
    );

    const gwsHome2 = computed(() =>
      require(`@/assets/images/gws-home-2-${currentTheme.value}.svg`)
    );

    const toggleTheme = () => {
      currentTheme.value = currentTheme.value === "light" ? "dark" : "light";
    };

    return {
      currentTheme,
      currentThemeImage,
      gwsHome2,
      toggleTheme,
    };
  },
});
</script>

<style lang="scss" scoped>
.gws-home {
  font-family: "Roboto", sans-serif;
}
.gws-home-1 {
  background-image: url("../../../assets/images/gws-home-1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px;

  @media (max-width: 768px) {
    padding: 30px;
  }

  .btn-custom-primary-purple {
    background-color: #3461ff;
    color: #ffffff;
  }

  .btn-custom-white-purple {
    background-color: #ffffff;
    color: #3461ff;
  }
}

.gws-home-2 {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr auto 2fr auto;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }

  @media (min-width: 768px) {
    & > * {
      border: 1px solid #ccc;
      border-top: none;
      border-left: none;
    }

    & > *:first-child {
      border-left: none;
    }

    & > *:last-child {
      border-left: none;
      border-right: none;
    }
  }
}

.gws-home-3 {
  padding: 30px;

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.gws-home-4 {
  padding: 60px;

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.gws-home-6 {
  padding: 30px;
  .carousel-wrapper {
    .custom-card {
      background-color: #f8f9ff;
      border: 0.4px solid #3461ff;
      * {
        color: black;
      }
    }

    .custom-first-card {
      background-color: #3461ff;
      * {
        color: #fff;
      }
    }

    .custom-carousel-wrapper {
      margin-bottom: 4vw;
    }

    .carousel-indicators button {
      background-color: #3461ff;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #3461ff;
      border-radius: 50%;
      border: 5px solid #3461ff;
    }
  }
}

.gws-home-7 {
  padding: 60px 0;

  .custom-quote-wrapper {
    position: relative;

    .custom-quote {
      position: absolute;
      top: -50px;
      right: -25px;
    }
  }
}

.gws-home-8 {
  .custom-bullet-wrapper {
    border-left: 1px solid #3461ff;
    display: flex;
    position: relative;
    &.no-border {
      border-left: none;
    }

    .custom-bullet {
      position: absolute;
      top: 0;
      left: -10px;
      font-size: 5rem;
    }
  }
}

.gws-pill {
  background: linear-gradient(90deg, #eaedff 0%, #f8f9ff 100%);
  color: #3461ff;
  padding: 10px 20px;
  border-radius: 40px;
}

.blue-section {
  background-color: #f0fbff;
  background-image: url("../../../assets/images/gws-map.svg");
  background-position: center top;
  background-repeat: no-repeat;
}

.footer-section {
  background-color: #000000;
  color: white;

  .gws-home-9 {
    background-image: url("../../../assets/images/gws-home-9.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 200px 15px;
  }

  .gws-home-10 {
    padding: 60px;
    background-image: url("../../../assets/images/gws-home-10.svg");
    background-position: center right;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }
}
.flex-row-reverse {
  flex-direction: row-reverse;
  gap: 2rem;
}

.dark {
  background-color: #000000;

  .themed-section * {
    color: #fff;
  }

  .gws-pill {
    color: #3461ff;
  }
}

.light {
  background-color: #fff;
}
</style>
