import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/master/p3mi/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useP3miFormStore = defineStore({
  id: "p3miFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          address: this.formData.address,
          email: this.formData.email,
          siup: this.formData.siup,
          npwp: this.formData.npwp,
          sip: this.formData.sip,
          password: this.formData.password,
          sip_file: this.formData.sip_file,
          sip_start_date: this.formData.sip_start_date,
          sip_end_date: this.formData.sip_end_date,
          city_id: this.formData.city_id,
          pic_name: this.formData.pic_name,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_signature_file: this.formData.pic_signature_file,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/companies`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async insertDataBasicAuth() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          address: this.formData.address,
          email: this.formData.email,
          siup: this.formData.siup,
          npwp: this.formData.npwp,
          sip: this.formData.sip,
          password: this.formData.password,
          sip_file: this.formData.sip_file,
          sip_start_date: this.formData.sip_start_date,
          sip_end_date: this.formData.sip_end_date,
          city_id: this.formData.city_id,
          pic_name: this.formData.pic_name,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_signature_file: this.formData.pic_signature_file,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postBasic(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/companies`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          address: this.formData.address,
          email: this.formData.email,
          siup: this.formData.siup,
          npwp: this.formData.npwp,
          sip: this.formData.sip,
          sip_file: this.formData.sip_file,
          sip_start_date: this.formData.sip_start_date,
          sip_end_date: this.formData.sip_end_date,
          city_id: this.formData.city_id,
          pic_name: this.formData.pic_name,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_signature_file: this.formData.pic_signature_file,
          pic_phone: this.formData.pic_phone,
          status: this.formData.status,
          reject_reason: this.formData.reject_reason,
        };

        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/companies/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async switchStatus(status: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          is_active: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/companies/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/companies/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || "",
        address: data.address || "",
        email: data.email || "",
        siup: data.siup || "",
        npwp: data.npwp || "",
        sip: data.sip || "",
        password: data.password || "",
        sip_file: data.sip_file || "",
        province_id: data.province_id || "",
        pic_signature_file: data.pic_signature_file || "",
        sip_start_date: data.sip_start_date || "",
        sip_end_date: data.sip_end_date || "",
        city_id: data.city_id || "",
        pic_name: data.pic_name || "",
        pic_id_number: data.pic_id_number || "",
        pic_email: data.pic_email || "",
        pic_phone: data.pic_phone || "",
        status: data.status || "",
        reject_reason: data.reject_reason || "",
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
