import ApiService from "@/core/services/ApiService";
import { FormDataCommit } from "@/core/types/gws-master/pengaturan-pembayaran/transaction/FormDataCommit";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useTransactionCommitFormStore = defineStore({
  id: "transactionCommitFormStore",
  state: () => ({
    stateIsLoading: false,
    stateFormData: {} as FormDataCommit,
    stateError: false,
    stateErrors: [] as string[],
    stateErrorMsg: "",
  }),
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData(
      cpmi_id,
      product_service,
      payment_method_id,
      product_service_amount,
      payment_fee,
      total_amount
    ) {
      this.stateFormData.cpmi_id = cpmi_id;
      this.stateFormData.product_service = product_service;
      this.stateFormData.payment_method_id = payment_method_id;
      this.stateFormData.product_service_amount = product_service_amount;
      this.stateFormData.payment_fee = payment_fee;
      this.stateFormData.total_amount = total_amount;
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/transaction/commit`,
          this.stateFormData as AxiosRequestConfig
        );
        if (response.status === 201) {
          this.stateError = false;
          return response.data;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        console.log(metaResp, "tess err");
        this.stateError = true;
      } finally {
        this.stateIsLoading = false;
      }
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([]);
    },
  },
});
