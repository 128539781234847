export const MasterCpmiStatus = [
  {
    name: "PRE_REGISTRATION",
    category: "PRE_REGISTRATION",
    order: 1,
    label: "Pra Pendaftaran",
  },
  {
    name: "REGISTRATION",
    category: "REGISTRATION",
    order: 2,
    label: "Pendaftaran",
  },
  {
    name: "PRE_SECURITY_BLACKLIST",
    category: "PRE_SECURITY",
    order: 3,
    label: "Pre-Security Clearance",
  },
  {
    name: "PRE_SECURITY_PASSED",
    category: "PRE_SECURITY",
    order: 3,
    label: "Pre-Security Clearance",
  },
  {
    name: "MEDICAL_PAYMENT",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },
  {
    name: "MEDICAL_REQUESTED",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },

  {
    name: "MEDICAL_RECEIVED",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },
  {
    name: "MEDICAL_PROCESSED",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },
  {
    name: "MEDICAL_COMPLETED_FIT",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },
  {
    name: "MEDICAL_COMPLETED_UNFIT",
    category: "MEDICAL",
    order: 4,
    label: "Pre-Medical",
  },
  {
    name: "BLK_REQUESTED",
    category: "BLK",
    order: 5,
    label: "Balai Latihan Kerja",
  },
  {
    name: "BLK_RECEIVED",
    category: "BLK",
    order: 5,
    label: "Balai Latihan Kerja",
  },
  {
    name: "BLK_PROCESSED",
    category: "BLK",
    order: 5,
    label: "Balai Latihan Kerja",
  },
  {
    name: "BLK_COMPLETED",
    category: "BLK",
    order: 5,
    label: "Balai Latihan Kerja",
  },
  {
    name: "LSP_REQUESTED",
    category: "LSP",
    order: 6,
    label: "Lembaga Sertifikasi Profesi",
  },
  {
    name: "LSP_RECEIVED",
    category: "LSP",
    order: 6,
    label: "Lembaga Sertifikasi Profesi",
  },
  {
    name: "LSP_PROCESSED",
    category: "LSP",
    order: 6,
    label: "Lembaga Sertifikasi Profesi",
  },
  {
    name: "LSP_COMPLETED",
    category: "LSP",
    order: 6,
    label: "Lembaga Sertifikasi Profesi",
  },
  {
    name: "DISNAKER",
    category: "DISNAKER",
    order: 7,
    label: "DISNAKER",
  },
  {
    name: "MIGRATION_REQUESTED",
    category: "MIGRATION",
    order: 8,
    label: "Imigrasi",
  },
  {
    name: "MIGRATION_RECEIVED",
    category: "MIGRATION",
    order: 8,
    label: "Imigrasi",
  },
  {
    name: "MIGRATION_PROCESSED",
    category: "MIGRATION",
    order: 8,
    label: "Imigrasi",
  },
  {
    name: "MIGRATION_COMPLETED",
    category: "MIGRATION",
    order: 8,
    label: "Imigrasi",
  },
  {
    name: "VISA_APPOINTMENT",
    category: "VISA",
    order: 9,
    label: "Pengajuan Visa",
  },
  {
    name: "VISA_RECEIVED",
    category: "VISA",
    order: 9,
    label: "Pengajuan Visa",
  },
  {
    name: "VISA_COMPLETED",
    category: "VISA",
    order: 9,
    label: "Pengajuan Visa",
  },
  {
    name: "VISA_REQUESTED",
    category: "VISA",
    order: 9,
    label: "Pengajuan Visa",
  },
  {
    name: "VISA_STATUS",
    category: "VISA",
    order: 9,
    label: "Pengajuan Visa",
  },
  {
    name: "ORIENTATION",
    category: "ORIENTATION",
    order: 10,
    label: "Orientasi Pra Pemberangkatan",
  },
  {
    name: "DEPARTURE",
    category: "DEPARTURE",
    order: 11,
    label: "Keberangkatan",
  },
  {
    name: "SYARIKAH_VERIFICATION",
    category: "SYARIKAH",
    order: 12,
    label: "Verifikasi Perusahaan Perekrut Kerja",
  },
];
