import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import MasterDataManagementRoutes from "@/router/webtool-superadmin/MasterDataManagementRoutes";
import JwtService from "@/core/services/JwtService";

const staticTitle = "Global Worker System";

const verifyAuth = (to, from, next) => {
  if (JwtService.getToken()) {
    next();
  } else {
    next({ name: "sign-in" });
  }
};

const verifyPermission = (to, from, next) => {
  const { path } = to;
  const permissions = JSON.parse(
    localStorage.getItem("acc-permission") as string
  );
  const foundRoute = permissions.find((route) => route.route === path);
  if (foundRoute && foundRoute.permissions.includes("R")) {
    next();
  } else {
    next({ name: "sign-in" });
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
    ],
  },
  {
    path: "/account-settings",
    name: "Account Settings",
    component: () =>
      import("@/views/gws-global-feature/account-settings/Index.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/crafted/home/Home.vue"),
  },
  //--------------------------------------------//
  //---------------SUPERADMIN-------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/super-admin/dashboard",
        name: "Superadmin Dashboard",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/dashboard/Index.vue"),
      },

      {
        path: "/super-admin/employee",
        component: RouterView,
        children: [
          {
            path: "/super-admin/employee/role",
            name: "EmployeeRole",
            meta: {
              title: `${staticTitle} - Master - Employee Management - Role`,
            },
            beforeEnter: verifyPermission,
            component: () => {
              return import(
                "@/views/gws-global-feature/employee/role/Index.vue"
              );
            },
          },
          {
            path: "/super-admin/employee/account",
            name: "EmployeeAccount",
            meta: {
              title: `${staticTitle} - Master - Employee Management - Account`,
            },
            beforeEnter: verifyPermission,
            component: () => {
              return import(
                "@/views/gws-global-feature/employee/account/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/super-admin/permohonan-perusahaan",
        name: "Superadmin Permohonan Perusahaan",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/permohonan-perusahaan/Index.vue"),
      },
      {
        path: "/super-admin/pengaturan-pembayaran",
        component: RouterView,
        children: [
          {
            path: "/super-admin/pengaturan-pembayaran/mastering-layanan",
            name: "MasteringLayanan",
            meta: {
              title: `${staticTitle} - Master - Pengaturan Pembayaran - Mastering Layanan`,
            },
            beforeEnter: verifyPermission,
            component: () => {
              return import(
                "@/views/gws-global-feature/pengaturan-pembayaran/mastering-layanan/Index.vue"
              );
            },
          },
          {
            path: "/super-admin/pengaturan-pembayaran/konfigurasi-fee",
            name: "KonfigurasiFee",
            meta: {
              title: `${staticTitle} - Master - Pengaturan Pembayaran - Konfigurasi Fee`,
            },
            beforeEnter: verifyPermission,
            component: () => {
              return import(
                "@/views/gws-global-feature/pengaturan-pembayaran/konfigurasi-fee/Index.vue"
              );
            },
          },
          {
            path: "/super-admin/pengaturan-pembayaran/payment-method",
            name: "KonfigurasiFee",
            meta: {
              title: `${staticTitle} - Master - Pengaturan Pembayaran - Payment Method`,
            },
            beforeEnter: verifyPermission,
            component: () => {
              return import(
                "@/views/gws-global-feature/pengaturan-pembayaran/payment-method/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/super-admin/account-settings",
        name: "Superadmin Account Settings",
        component: () =>
          import("@/views/webtool-superadmin/account-settings/Index.vue"),
      },
      {
        path: "/super-admin/cert-verification",
        name: "Superadmin Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/super-admin/master-data",
        component: RouterView,
        children: MasterDataManagementRoutes(staticTitle),
      },
      {
        path: "/super-admin/approval-sip",
        name: "Approval Sip",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/approval-sip/Index.vue"),
      },
      {
        path: "/super-admin/settlement",
        name: "Settlement",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/settlement/Index.vue"),
      },
      {
        path: "/super-admin/fee-config",
        name: "FeeConfiguration",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/fee-config/Index.vue"),
      },
      {
        path: "/super-admin/payment-skck/:id",
        name: "Superadmin Payment Skck Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/payment-skck/Index.vue"
          ),
      },
      {
        path: "/super-admin/submission-detail/:id",
        name: "Superadmin Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
      {
        path: "/super-admin/quota-config",
        name: "QuotaConfig",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-superadmin/quota-config/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------AGENT----------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/agent/dashboard",
        name: "Agent Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-agent/dashboard/Index.vue"),
      },
      {
        path: "/agent/employee/role",
        name: "Agent Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/agent/employee/account",
        name: "Agent Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/agent/cpmi",
        name: "Agent CPMI",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-mainpage/Index.vue"),
      },
      {
        path: "/agent/cpmi-detail/:id",
        name: "Agent CPMI Detail",
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-detail/Index.vue"),
      },
      {
        path: "/agent/submission-detail/:id",
        name: "Agent Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
      {
        path: "/agent/cert-verification",
        name: "Agent Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/agent/submission-detail/:id",
        name: "Agent Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
      {
        path: "/agent/payment-skck/:id",
        name: "Agent Payment Skck Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/payment-skck/Index.vue"
          ),
      },
      {
        path: "/agent/account-settings",
        name: "Agent Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------P3MI----------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/p3mi/account-settings",
        name: "P3MI Account Settings",
        component: () =>
          import("@/views/webtool-p3mi/account-settings/Index.vue"),
      },
      {
        path: "/p3mi/dashboard",
        name: "P3MI Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-p3mi/dashboard/Index.vue"),
      },
      {
        path: "/p3mi/informasi-pekerja",
        name: "P3MI Informasi Pekerja",
        // beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/informasi-pekerja/Index.vue"),
      },
      {
        path: "/p3mi/employee/role",
        name: "P3MI Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/p3mi/employee/account",
        name: "P3MI Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-p3mi/account-settings/Index.vue"),
      },
      {
        path: "/p3mi/cpmi",
        name: "P3MI CPMI",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-mainpage/Index.vue"),
      },
      {
        path: "/p3mi/cpmi-detail/:id",
        name: "P3MI CPMI Detail",
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-detail/Index.vue"),
      },
      {
        path: "/p3mi/cert-verification",
        name: "P3MI Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/super-admin/submission-detail/:id",
        name: "P3MI Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
      {
        path: "/p3mi/visa",
        name: "Mastering Visa",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-p3mi/mastering-visa/Index.vue"),
      },
      {
        path: "/p3mi/submission-detail/:id",
        name: "P3MI Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------JOBFAIR--------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/jobfair/account-settings",
        name: "Jobfair Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
      {
        path: "/jobfair/dashboard",
        name: "Jobfair Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-jobfair/dashboard/Index.vue"),
      },
      {
        path: "/jobfair/employee/role",
        name: "Jobfair Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/jobfair/employee/account",
        name: "Jobfair Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/jobfair/cpmi",
        name: "Jobfair CPMI",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-mainpage/Index.vue"),
      },
      {
        path: "/jobfair/operational",
        name: "Jobfair Operational Quota",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-jobfair/quota-operational/Index.vue"),
      },
      {
        path: "/jobfair/cpmi-detail/:id",
        name: "Jobfair CPMI Detail",
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-detail/Index.vue"),
      },
      {
        path: "/jobfair/submission-detail/:id",
        name: "Jobfair Submission Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/submission-skck/Index.vue"
          ),
      },
      {
        path: "/jobfair/payment-skck/:id",
        name: "Payment Skck Detail",
        component: () =>
          import(
            "@/views/gws-global-feature/cpmi/cpmi-detail/payment-skck/Index.vue"
          ),
      },
      {
        path: "/jobfair/cert-verification",
        name: "Jobfair Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/jobfair/visa",
        name: "Jobfair Mastering Visa",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-p3mi/mastering-visa/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------SYARIKAH-------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/syarikah/account-settings",
        name: "Perusahaan Perekrut Kerja Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
      {
        path: "/syarikah/dashboard",
        name: "Perusahaan Perekrut Kerja Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-syarikah/dashboard/Index.vue"),
      },

      {
        path: "/syarikah/informasi-pekerja",
        name: "Perusahaan Perekrut Kerja Informasi Pekerja",
        // beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/informasi-pekerja/Index.vue"),
      },
      {
        path: "/syarikah/employee/role",
        name: "Perusahaan Perekrut Kerja Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/syarikah/employee/account",
        name: "Perusahaan Perekrut Kerja Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/syarikah/cpmi",
        name: "Perusahaan Perekrut Kerja CPMI",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-mainpage/Index.vue"),
      },
      {
        path: "/syarikah/cpmi-detail/:id",
        name: "Perusahaan Perekrut Kerja CPMI Detail",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cpmi/cpmi-detail/Index.vue"),
      },
      {
        path: "/syarikah/cert-verification",
        name: "Perusahaan Perekrut Kerja Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/syarikah/visa",
        name: "Mastering Visa Perusahaan Perekrut Kerja",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-p3mi/mastering-visa/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------MEDICAL-------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/medical/account-settings",
        name: "Medical Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
      {
        path: "/medical/dashboard",
        name: "Medical Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-medical/dashboard/Index.vue"),
      },
      {
        path: "/medical/employee/role",
        name: "Medical Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/medical/employee/account",
        name: "Medical Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/medical/products",
        name: "Medical Product",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-medical/product-management/Index.vue"),
      },
      {
        path: "/medical/doctors",
        name: "Medical Doctors",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-medical/doctor-management/Index.vue"),
      },
      {
        path: "/medical/operational",
        name: "Medical Operational Quota",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-medical/quota-operational/Index.vue"),
      },
      {
        path: "/medical/approval",
        component: RouterView,
        children: [
          {
            path: "/medical/approval/submission",
            name: "Medical Pengajuan",
            component: () => {
              return import(
                "@/views/webtool-medical/approval-treatment/submission/Index.vue"
              );
            },
          },
          {
            path: "/medical/approval/reservation",
            name: "Medical Reservasi",
            component: () => {
              return import(
                "@/views/webtool-medical/approval-treatment/reservation/Index.vue"
              );
            },
          },
          {
            path: "/medical/approval/digital-data",
            name: "Medical Data Digital",
            component: () => {
              return import(
                "@/views/webtool-medical/approval-treatment/digital-data/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/medical/report",
        component: RouterView,
        children: [
          {
            path: "/medical/report/request",
            name: "Medical Laporan Permohonan",
            component: () => {
              return import(
                "@/views/webtool-medical/report/report-request/Index.vue"
              );
            },
          },
          {
            path: "/medical/report/transactions",
            name: "Medical Laporan Transaksi",
            component: () => {
              return import(
                "@/views/webtool-medical/report/report-transaction/Index.vue"
              );
            },
          },
          {
            path: "/medical/report/transactions/detail",
            name: "Medical Laporan Transaksi Detail",
            component: () => {
              return import(
                "@/views/webtool-medical/report/report-transaction-detail/Index.vue"
              );
            },
            props: true,
          },
        ],
      },
      {
        path: "/medical/cert-verification",
        name: "Medical Certificate",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/cert-verification/Index.vue"),
      },
      {
        path: "/medical/visa",
        name: "Medical Visa Perusahaan Perekrut Kerja",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-p3mi/mastering-visa/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------VFS------------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/vfs/account-settings",
        name: "VFS Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
      {
        path: "/vfs/dashboard",
        name: "VFS Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-vfs/dashboard/Index.vue"),
      },
      {
        path: "/vfs/biometrics-cpmi",
        name: "VFS CPMI Biometrics",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-vfs/cpmi-biometrics/Index.vue"),
      },
    ],
  },
  {
    path: "/report/sample",
    beforeEnter: verifyAuth,
    component: () => import("@/views/crafted/report/SampleReport.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  //--------------------------------------------//
  //-----------------POLRI------------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/polri/dashboard",
        name: "Polri Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-polri/dashboard/Index.vue"),
      },
      {
        path: "/polri/skck",
        name: "Polri Skck",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/webtool-polri/submission-skck/Index.vue"),
      },
      {
        path: "/polri/employee/role",
        name: "Polri Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/polri/employee/account",
        name: "Polri Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/polri/submission-skck-detail/:id",
        name: "Polri Submission Detail",
        component: () =>
          import("@/views/webtool-polri/submission-skck-detail/Index.vue"),
      },
      {
        path: "/polri/account-settings",
        name: "Polri Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
    ],
  },
  //--------------------------------------------//
  //-----------------AP2TKI---------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/ap2tki/dashboard",
        name: "AP2TKI Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-ap2tki/dashboard/Index.vue"),
      },
      {
        path: "/ap2tki/account-settings",
        name: "AP2TKI Account Settings",
        component: () =>
          import("@/views/gws-global-feature/account-settings/Index.vue"),
      },
      {
        path: "/ap2tki/employee/role",
        name: "AP2TKI Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/ap2tki/employee/account",
        name: "AP2TKI Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/ap2tki/master-data",
        component: RouterView,
        children: [
          {
            path: "/ap2tki/master-data/blk",
            name: "Balai Latihan Kerja",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - BLK`,
            },
            component: () => {
              return import("@/views/webtool-ap2tki/master-data/blk/Index.vue");
            },
          },
          {
            path: "/ap2tki/master-data/lsp",
            name: "Lembaga Sertifikasi Profesi",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - LSP`,
            },
            component: () => {
              return import("@/views/webtool-ap2tki/master-data/lsp/Index.vue");
            },
          },
          {
            path: "/ap2tki/master-data/kejuruan-blk",
            name: "Kejuruan BLK",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - Kejuruan`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/master-data/vocation/Index.vue"
              );
            },
          },
          {
            path: "/ap2tki/master-data/jenis-sertifikasi",
            name: "Jenis Sertifikasi",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - Jenis Sertifikasi`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/master-data/typecert/Index.vue"
              );
            },
          },
          {
            path: "/ap2tki/master-data/paket-sertifikasi",
            name: "Paket Sertifikasi",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - Paket Sertifikasi`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/master-data/packagecert/Index.vue"
              );
            },
          },
          {
            path: "/ap2tki/master-data/schedule",
            name: "Jadwal",
            meta: {
              title: `${staticTitle} - AP2TKI - Master Data - Jadwal`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/master-data/schedule/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/ap2tki/approval-training",
        component: RouterView,
        children: [
          {
            path: "/ap2tki/approval-training/request-pelatihan",
            name: "Pengajuan Pelatihan",
            meta: {
              title: `${staticTitle} - AP2TKI - Approval Pelatihan - Pengajuan Pelatihan`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/approval-training/pengajuan/Index.vue"
              );
            },
          },
          {
            path: "/ap2tki/approval-training/monitoring-pelatihan",
            name: "Monitoring Pelatihan",
            meta: {
              title: `${staticTitle} - AP2TKI - Approval Pelatihan - Monitoring Pelatihan`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/approval-training/monitoring/Index.vue"
              );
            },
          },
          {
            path: "/ap2tki/approval-training/hasil-pelatihan",
            name: "Result Pelatihan",
            meta: {
              title: `${staticTitle} - AP2TKI - Approval Pelatihan - Hasil Pelatihan`,
            },
            component: () => {
              return import(
                "@/views/webtool-ap2tki/approval-training/result/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/ap2tki/report",
        component: RouterView,
        children: [
          {
            path: "/ap2tki/report/blk",
            name: "AP2TKI Report BLK",
            meta: {
              title: `${staticTitle} - AP2TKI - Report - BLK`,
            },
            component: () => {
              return import("@/views/webtool-ap2tki/report/blk/Index.vue");
            },
          },
          {
            path: "/ap2tki/report/lsp",
            name: "AP2TKI Report LSP",
            meta: {
              title: `${staticTitle} - AP2TKI - Report - LSP`,
            },
            component: () => {
              return import("@/views/webtool-ap2tki/report/lsp/Index.vue");
            },
          },
        ],
      },
    ],
  },
  //--------------------------------------------//
  //-----------------BLK------------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/blk/dashboard",
        name: "BLK Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-blk/dashboard/Index.vue"),
      },
      {
        path: "/blk/account-settings",
        name: "BLK Account Settings",
        component: () =>
          import("@/views/webtool-blk/account-settings/Index.vue"),
      },
      {
        path: "/blk/employee/role",
        name: "BLK Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/blk/employee/account",
        name: "BLK Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/blk/course-settings",
        component: RouterView,
        children: [
          {
            path: "/blk/course-settings/class-info",
            name: "BLK Class Settings",
            meta: {
              title: `${staticTitle} - BLK - Course Settings - Class`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/course-settings/class-information/Index.vue"
              );
            },
          },
          {
            path: "/blk/course-settings/schedule",
            name: "BLK Schedule Settings",
            meta: {
              title: `${staticTitle} - BLK - Course Settings - Schedule`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/course-settings/schedule/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/blk/service-process",
        component: RouterView,
        children: [
          {
            path: "/blk/service-process/service-request",
            name: "BLK Service Request",
            meta: {
              title: `${staticTitle} - BLK - Service Process - Service Request`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/service-process/service-request/Index.vue"
              );
            },
          },
          {
            path: "/blk/service-process/course-monitoring",
            name: "BLK Course Monitoring",
            meta: {
              title: `${staticTitle} - BLK - Service Request - Course Monitoring`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/service-process/course-monitoring/Index.vue"
              );
            },
          },
          {
            path: "/blk/service-process/course-monitoring-details",
            name: "BLK Course Monitoring Detail",
            meta: {
              title: `${staticTitle} - BLK - Service Request - Course Monitoring Detail`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/service-process/course-monitoring/MonitoringDetail.vue"
              );
            },
          },
        ],
      },
      {
        path: "/blk/report",
        component: RouterView,
        children: [
          {
            path: "/blk/report/submission-report",
            name: "BLK Submission Report",
            meta: {
              title: `${staticTitle} - BLK - Report - Submission Report`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/report/submission-report/Index.vue"
              );
            },
          },
          {
            path: "/blk/report/student-report",
            name: "BLK Student Report",
            meta: {
              title: `${staticTitle} - BLK - Report - Student Report`,
            },
            component: () => {
              return import(
                "@/views/webtool-blk/report/student-report/Index.vue"
              );
            },
          },
        ],
      },
    ],
  },
  //--------------------------------------------//
  //-----------------LSP------------------------//
  //--------------------------------------------//
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: verifyAuth,
    children: [
      {
        path: "/lsp/dashboard",
        name: "LSP Dashboard",
        beforeEnter: verifyPermission,
        component: () => import("@/views/webtool-lsp/dashboard/Index.vue"),
      },
      {
        path: "/lsp/account-settings",
        name: "LSP Account Settings",
        component: () =>
          import("@/views/webtool-lsp/account-settings/Index.vue"),
      },
      {
        path: "/lsp/employee/role",
        name: "LSP Role",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/role/Index.vue"),
      },
      {
        path: "/lsp/employee/account",
        name: "LSP Account",
        beforeEnter: verifyPermission,
        component: () =>
          import("@/views/gws-global-feature/employee/account/Index.vue"),
      },
      {
        path: "/lsp/cert-approval",
        component: RouterView,
        children: [
          {
            path: "/lsp/cert-approval/cert-submission",
            name: "LSP Cert Submission",
            meta: {
              title: `${staticTitle} - LSP - Cert Approval - Cert Submission`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/approval-certification/certification-submission/Index.vue"
              );
            },
          },
          {
            path: "/lsp/cert-approval/cert-reservation",
            name: "LSP Cert Reservation",
            meta: {
              title: `${staticTitle} - LSP - Cert Approval - Cert Reservation`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/approval-certification/certification-reservation/Index.vue"
              );
            },
          },
          {
            path: "/lsp/cert-approval/bnsp-cert",
            name: "LSP BNSP Cert Upload",
            meta: {
              title: `${staticTitle} - LSP - Cert Approval - BNSP Cert Upload`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/approval-certification/bnsp-certificate-upload/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/lsp/cert-settings",
        component: RouterView,
        children: [
          {
            path: "/lsp/cert-setting/profession",
            name: "LSP Profession Certification",
            meta: {
              title: `${staticTitle} - LSP - Cert Settings - Profession Cert`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/certification-settings/proffesion-cert/Index.vue"
              );
            },
          },
        ],
      },
      {
        path: "/lsp/report",
        component: RouterView,
        children: [
          {
            path: "/lsp/report/submission",
            name: "LSP Submission Report",
            meta: {
              title: `${staticTitle} - LSP - Report - Submission Report`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/report/submission-report/Index.vue"
              );
            },
          },
          {
            path: "/lsp/report/transaction",
            name: "LSP Transaction Report",
            meta: {
              title: `${staticTitle} - LSP - Report - Transaction`,
            },
            component: () => {
              return import(
                "@/views/webtool-lsp/report/transaction-report/Index.vue"
              );
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
