import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ListItem } from "@/core/types/gws-agent/cpmi/ListItem";
import { ListItem as AgentItem } from "@/core/types/gws-master/master/agent/ListItem";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { Option } from "@/core/types/misc/Option";
import { ListItem as JobFairItem } from "@/core/types/gws-master/master/jobfair/ListItem";
import { CPMIStatusEnum } from "@/store/enums/StatusEnums";
import { AdvFiltering } from "@/core/types/misc/AdvFiltering";
export const useCpmiListStore = defineStore({
  id: "cpmiListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      stateAdvFiltering: {
        agentId: "",
        status: "",
        companyId: "",
        syarikahId: "",
        visaNumber: "",
        employerName: "",
        dateRange: [] as string[],
      } as AdvFiltering,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      stateCompanyOption: [] as Option[],
      stateSyarikahOption: [] as Option[],
      stateStatusEnum: CPMIStatusEnum,
      stateJobFairDetail: {
        province: {
          id: "",
        },
        city: {
          id: "",
        },
        id: "",
      } as JobFairItem,
      stateAgentOption: [] as Option[],
      stateCpmiOption: [] as Option[],
      stateJobFairOption: [] as Option[],
      stateSelectedAgentDetail: {} as AgentItem,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    companyOption(state) {
      return state.stateCompanyOption;
    },
    agentOption(state) {
      return state.stateAgentOption;
    },
    cpmiOption(state) {
      return state.stateCpmiOption;
    },
    syarikahOption(state) {
      return state.stateSyarikahOption;
    },
    jobFairs(state) {
      return state.stateJobFairOption;
    },
    selectedAgent(state) {
      return state.stateSelectedAgentDetail;
    },
    jobFairDetail(state) {
      return state.stateJobFairDetail;
    },
    advFiltering(state) {
      return state.stateAdvFiltering;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      let adv = "";
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.advFiltering.agentId) {
        adv += `agent_id:eq:${this.advFiltering.agentId};`;
      }
      if (this.advFiltering.status) {
        adv += `status:eq:${this.advFiltering.status};`;
      }
      if (this.advFiltering.companyId) {
        adv += `company:eq:${this.advFiltering.companyId};`;
      }
      if (this.advFiltering.syarikahId) {
        adv += `syarikah:eq:${this.advFiltering.syarikahId};`;
      }
      if (this.advFiltering.dateRange.length > 0) {
        adv += `created_at:gte:${this.advFiltering.dateRange[0]};created_at:lte:${this.advFiltering.dateRange[1]};`;
      }
      if (this.advFiltering.visaNumber) {
        adv += `visa_number:eq:${this.advFiltering.visaNumber};`;
      }
      if (this.advFiltering.employerName) {
        adv += `employer_name:${this.advFiltering.employerName};`;
      }
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `cpmi?${new URLSearchParams(params).toString()}${
              adv ? `&adv_filter=` + adv : ""
            }`
          );
        this.stateDisplayData = response.data.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getLookupCompany() {
      this.stateCompanyOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `companies?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.p3mi;
        listCompany.forEach((el) => {
          this.stateCompanyOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupSyarikah() {
      this.stateSyarikahOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `syarikah?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.syarikah;
        listCompany.forEach((el) => {
          this.stateSyarikahOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAgent() {
      this.stateAgentOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `agents?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.agent_p3mi;
        listCompany.forEach((el) => {
          this.stateAgentOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupCpmi() {
      this.stateCpmiOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `cpmi?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data;
        listCompany.forEach((el) => {
          this.stateCpmiOption.push({
            label: `${el.first_name} ${el.middle_name} ${el.last_name}`,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupJobfair(city_id: string) {
      this.stateJobFairOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
        city_id: city_id,
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `job-fairs?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.job_fair;
        listCompany.forEach((el) => {
          this.stateJobFairOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getAgentDetail(agent_id) {
      this.stateSelectedAgentDetail = {} as AgentItem;
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `agents/${agent_id}`
        );
        const agentDetail = response.data.data.agent_p3mi;
        this.stateSelectedAgentDetail = agentDetail;
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupJobfairDetail(id: string) {
      try {
        const response: AxiosResponse<ApiResponse<JobFairItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `job-fairs/${id}`
          );
        this.stateJobFairDetail = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
