import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ListData } from "@/core/types/gws-medical/product-management/ListData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { ListCategories } from "@/core/types/gws-medical/product-management/ListCategories";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { Option } from "@/core/types/misc/Option";

const authStore = useAuthenticationStore();
export const useOperationalQuotaListStore = defineStore({
  id: "operationalQuotaListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      stateCategory: [] as ListCategories[],
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListData[],
      statePackagesOption: [] as Option[],
      stateActiveCategoryId: "",
      stateFacilityId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    categories(state) {
      return state.stateCategory;
    },
    activeCatId(state) {
      return state.stateActiveCategoryId;
    },
    packages(state) {
      return state.statePackagesOption;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateIsLoading = true;
      authStore.getUserInfo();
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListData[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities/${
              authStore.user.org_id || ""
            }/operational?${new URLSearchParams(params).toString()}`
          );
        this.stateDisplayData = response.data.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getCategory(isPageRefresh = true) {
      this.stateIsLoading = true;
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListCategories[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/categories?${new URLSearchParams(params).toString()}`
          );
        this.stateCategory = response.data.data;
        this.stateActiveCategoryId = this.stateCategory[0].id;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getPackages() {
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
        category_id: this.stateActiveCategoryId || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        const response: AxiosResponse<ApiResponse<ListData[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/packages?${new URLSearchParams(params).toString()}`
          );
        // this.statePackagesOption = response.data.data;
        this.statePackagesOption = [];
        response.data.data.forEach((el) => {
          this.statePackagesOption.push({
            value: el.id,
            label: el.name,
          });
        });
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
