enum CPMIStatusEnum {
  PRE_REGISTRATION = 'PRE_REGISTRATION',
  PRE_REGISTRATION_REJECTED = 'PRE_REGISTRATION_REJECTED',
  REGISTRATION = 'REGISTRATION',
  REGISTRATION_REJECTED = 'REGISTRATION_REJECTED',
  PRE_SECURITY = 'PRE_SECURITY',
  PRE_SECURITY_BLACKLIST = 'PRE_SECURITY_BLACKLIST',
  PRE_SECURITY_PASSED = 'PRE_SECURITY_PASSED',
  MEDICAL_PAYMENT = 'MEDICAL_PAYMENT',
  MEDICAL_REQUESTED = 'MEDICAL_REQUESTED',
  MEDICAL_RECEIVED = 'MEDICAL_RECEIVED',
  MEDICAL_PROCESSED = 'MEDICAL_PROCESSED',
  MEDICAL_COMPLETED = 'MEDICAL_COMPLETED',
  MEDICAL_COMPLETED_FIT = 'MEDICAL_COMPLETED_FIT',
  MEDICAL_COMPLETED_UNFIT = 'MEDICAL_COMPLETED_UNFIT',
  MEDICAL_REJECTED = 'MEDICAL_REJECTED',
  BLK_REQUESTED = 'BLK_REQUESTED',
  BLK_RECEIVED = 'BLK_RECEIVED',
  BLK_PROCESSED = 'BLK_PROCESSED',
  BLK_COMPLETED = 'BLK_COMPLETED',
  BLK_REJECTED = 'BLK_REJECTED',
  LSP_REQUESTED = 'LSP_REQUESTED',
  LSP_RECEIVED = 'LSP_RECEIVED',
  LSP_PROCESSED = 'LSP_PROCESSED',
  LSP_COMPLETED = 'LSP_COMPLETED',
  LSP_REJECTED = 'LSP_REJECTED',
  DISNAKER = 'DISNAKER',
  MIGRATION_REQUESTED = 'MIGRATION_REQUESTED',
  MIGRATION_RECEIVED = 'MIGRATION_RECEIVED',
  MIGRATION_PROCESSED = 'MIGRATION_PROCESSED',
  MIGRATION_COMPLETED = 'MIGRATION_COMPLETED',
  VISA_APPOINTMENT = 'VISA_APPOINTMENT',
  VISA_RECEIVED = 'VISA_RECEIVED',
  VISA_COMPLETED = 'VISA_COMPLETED',
  VISA_REQUESTED = 'VISA_REQUESTED',
  VISA_STATUS = 'VISA_STATUS',
  ORIENTATION = 'ORIENTATION',
  DEPARTURE = 'DEPARTURE',
  SYARIKAH_VERIFICATION = 'SYARIKAH_VERIFICATION',
  SYARIKAH_COMPLETED = 'SYARIKAH_COMPLETED',
}

enum SubmissionSkckStatusEnum {
  APLICATION_INFORMATION = "INCOMPLETE",
  PERSONAL_DATA = "PROCESSED",
  WAIT_PROCESS = "WAIT_PROCESS",
  PROCESS_SUBMISSION = "COMPLETED",
}

enum SubmissionSkckStepEnum {
  APPLICATION_INFORMATION = "APPLICATION_INFORMATION",
  PERSONAL_DATA = "PERSONAL_DATA",
  RELATIONSHIP = "RELATIONSHIP",
  EDUCATION = "EDUCATION",
  CRIMINAL_CASES = "CRIMINAL_CASES",
  VIOLATION = "VIOLATION",
  OTHER_INFORMATION = "OTHER_INFORMATION",
  PHYSICAL_CHARACTERISTICS = "PHYSICAL_CHARACTERISTICS",
  ATTACHMENT = "ATTACHMENT",
  DESCRIPTION = "DESCRIPTION",
  APPLICATION_PROCESS = "APPLICATION_PROCESS",
}

export { CPMIStatusEnum, SubmissionSkckStatusEnum, SubmissionSkckStepEnum };
