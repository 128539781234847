import { defineStore } from "pinia";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { InstructorFormData as FormData } from "@/core/types/gws-blk/InstructorFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";

const authStore = useAuthenticationStore();
export const useBlkInstructorFormStore = defineStore({
  id: "blkInstructorFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateUpdateId: "",
      stateUpdateBlk: "",
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          photo_file: this.formData.photo_file,
          cv_file: this.formData.cv_file,
          bnsp_file: this.formData.bnsp_file,
          bnsp_expired: this.formData.bnsp_expired,
          position: this.formData.position,
          vocational_id: this.formData.vocational_id,
          email: this.formData.email,
          nik: this.formData.nik,
          phone: this.formData.phone,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/blk/${authStore.user.org_id}/instructor-blk`,
          payload as AxiosRequestConfig
        );
        console.log(response);
        if (response.status === 201) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const errMsg = error.response.message;
        this.stateErrors.push(errMsg);
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          photo_file: this.formData.photo_file,
          cv_file: this.formData.cv_file,
          bnsp_file: this.formData.bnsp_file,
          bnsp_expired: this.formData.bnsp_expired,
          position: this.formData.position,
          vocational_id: this.formData.vocational_id,
          email: this.formData.email,
          nik: this.formData.nik,
          phone: this.formData.phone,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/blk/${authStore.user.org_id}/instructor-blk/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        console.log(response);
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const errMsg = error.response.message;
        this.stateErrors.push(errMsg);
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/blk/${authStore.user.org_id}/instructor-blk/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = data as FormData;
    },
    resetFormData() {
      this.stateFormData = {} as FormData;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
